
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import { FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons-vue';
import useSpotCheckProgramFl from '@/hooks/checking/spotChecking/checkingConfirm/useSpotCheckProgramFl';
import TableWrapper from '@/views/Payment/components/TableWrapper.vue';
import {
  fetchFinancialConfirm,
  fetchFinancialReturn,
  fetchFinancialtoFl,
  financialVerifyReturn,
  financialVerifyConfirm,
  verifyFinancialCheckBeforeConfirm,
  financialAddComments2
} from '@/API/checking/spotChecking';
import ReturnConfirmModal from '../components/returnModalConfirm.vue';
import downloadFile from '@/utils/payment/downloadFile';
import GetColumnWidth from '@/utils/payment/getColumnWidth';

export default defineComponent({
  name: 'App',
  props: {},
  setup() {
    const {
      data,
      column,
      summary,
      timeLine,
      id,
      status,
      statusList,
      getStatusList,
      getTableData,
      isAllControllingReviewed,
      isAllConfirmToSipo
    } = useSpotCheckProgramFl();
    const search = () => {
      getTableData();
    };
    const reset = () => {
      status.value = '';
      getTableData();
    };

    const commentModalVisible = ref(false);
    const comment = ref('');

    let resolveAddComment: ((value: unknown) => void) | null = null;
    let rejectAddComment: ((reson?: any) => void) | null = null;

    const handleConfirm = async (addComent: boolean) => {
      const result = await financialVerifyConfirm([id.value]);
      if (!result.data) {
        // message.warn(result.message);
        return;
      }
      const checkBeforeConfirm = await verifyFinancialCheckBeforeConfirm([summary.value.id]);
      await new Promise((resolve, reject) => {
        if (checkBeforeConfirm) {
          resolveAddComment = resolve;
          rejectAddComment = reject;
          comment.value = '';
          commentModalVisible.value = true;
        } else {
          resolve(null);
        }
      }).finally(() => {
        commentModalVisible.value = false;
      });
      fetchFinancialConfirm([id.value]).then((res) => {
        search();
        message.success('Confirm successfully!');
      });
    };

    const handleAddComment = async () => {
      if (comment.value.length === 0) {
        message.error('Reason is required');
        return;
      }
      await financialAddComments2(comment.value, [summary.value.id]);
      resolveAddComment?.(null);
    };

    const handleCancelComment = () => {
      rejectAddComment?.();
    };

    const returnModalVisible = ref(false);
    const returnCommentModalVisible = ref(false);

    const handleReturn = async () => {
      const result = await financialVerifyReturn([id.value]);
      if (!result.data) {
        // message.warning(result.message);
      } else {
        returnModalVisible.value = true;
      }
    };
    const handleReturnConfirm = async (comment: string) => {
      if (comment.length === 0) {
        message.error('Reason is required');
        return;
      }
      await financialAddComments2(comment, [summary.value.id]);
      await fetchFinancialReturn([id.value]);
      search();
      message.success('Return successfully!');
      returnCommentModalVisible.value = false;
    };

    const handleConfirmFl = async () => {
      const res = await fetchFinancialtoFl(id.value);
      if (res === null) {
        search();
        message.success('ConfirmF&L successfully!');
      }
    };
    const router = useRouter();
    const { commit } = useStore();
    const handleGoTo = (record: any) => {
      commit('spotChecking/updatespotCheckConfirmProgramFl', record);
      router.push({
        path: '/spotCheckingControlling/flVinView'
      });
    };
    const init = () => {
      getStatusList();
      getTableData();
    };
    init();
    const handleExport = (record: any) => {
      const params = {
        url: `/claimapi/spotCheckConfirm/financial/exportExcel?payoutProgramId=${record.programId}`,
        method: 'post'
      };
      downloadFile(params);
    };
    const tableWidth = ref(GetColumnWidth(column));
    return {
      comment,
      commentModalVisible,
      data,
      column,
      summary,
      timeLine,
      status,
      statusList,
      isPagination: false,
      search,
      reset,
      handleGoTo,
      handleConfirm,
      handleReturn,
      handleConfirmFl,
      isAllControllingReviewed,
      isAllConfirmToSipo,
      returnModalVisible,
      returnCommentModalVisible,
      handleReturnConfirm,
      handleExport,
      tableWidth,
      handleAddComment,
      handleCancelComment
    };
  },
  components: {
    TableWrapper,
    FileSearchOutlined,
    ReturnConfirmModal
  }
});
